export class UserSettings {
  name: string;
  avatarUrl: string | null;
  showInTops: boolean;
  showInPlayers: boolean;

  constructor(data?: Partial<UserSettings>) {
    this.name = data?.name || '';
    this.avatarUrl = data?.avatarUrl || null;
    this.showInTops = data?.showInTops ?? true;
    this.showInPlayers = data?.showInPlayers ?? true;
  }
}
