import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserSettingsService } from '../services/user-settings.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileInterceptor implements HttpInterceptor {
  private lastAttemptedUrl: string | null = null;
  private isRedirecting = false;

  constructor(
    private userSettingsService: UserSettingsService,
    private router: Router
  ) {}

  private shouldRedirectToProfile(url: string): boolean {
    // No redirigimos para URLs externas o APIs
    if (!url || url.includes('picsum.photos')) {
      return false;
    }

    // Normalizamos la URL para asegurarnos de que empiece con /
    const normalizedUrl = url.startsWith('/') ? url : `/${url}`;

    // Evitamos redirecciones si:
    // 1. Ya estamos en la página de perfil
    // 2. Estamos en medio de una redirección
    // 3. Intentamos acceder a la misma URL que causó la última redirección
    if (normalizedUrl.startsWith('/profile') || 
        this.isRedirecting || 
        normalizedUrl === this.lastAttemptedUrl) {
      return false;
    }

    const settings = this.userSettingsService.getCurrentSettings();
    const needsProfile = !settings?.name || settings.name.trim() === '';

    if (needsProfile) {
      this.lastAttemptedUrl = normalizedUrl;
    }

    return needsProfile;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Para solicitudes HTTP, usamos la URL actual de navegación
    const currentUrl = this.router.url;
    
    if (this.shouldRedirectToProfile(currentUrl)) {
      this.isRedirecting = true;
      this.router.navigate(['/profile']);
      setTimeout(() => {
        this.isRedirecting = false;
      }, 100);
    }
    
    return next.handle(request);
  }
}
