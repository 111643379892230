import { Routes } from '@angular/router';
import { ProfileGuard } from './guards/profile.guard';

export const routes: Routes = [
  {
    path: 'profile',
    loadComponent: () => import('./profile/profile.page').then(m => m.ProfilePage)
  },
  {
    path: 'matches',
    loadComponent: () => import('./matches/matches.page').then(m => m.MatchesPage)
  },
  {
    path: 'likes',
    loadComponent: () => import('./likes/likes.page').then(m => m.LikesPage)
  },
  {
    path: 'chat',
    loadComponent: () => import('./chat/chat.page').then(m => m.ChatPage)
  },
  {
    path: 'oferta',
    loadComponent: () => import('./oferta/oferta.page').then(m => m.OfertaPage)
  },
  {
    path: 'music',
    loadComponent: () => import('./music/music.page').then(m => m.MusicPage)
  },
  {
    path: 'people',
    loadComponent: () => import('./people/people.page').then(m => m.PeoplePage)
  },
  {
    path: 'tabs',
    canActivate: [ProfileGuard],
    loadChildren: () => import('./tabs/tabs.routes').then(m => m.routes)
  },
  {
    path: '',
    redirectTo: 'tabs',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'tabs'
  }
];
