import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [IonApp, IonRouterOutlet, RouterOutlet],
})
export class AppComponent {
  constructor() {
    // Forzar tema oscuro siempre
    this.enableDarkTheme();
  }

  private enableDarkTheme() {
    document.body.classList.add('dark');
    const metaColor = document.querySelector('meta[name="theme-color"]');
    if (metaColor) {
      metaColor.setAttribute('content', '#121212');
    }
  }
}
