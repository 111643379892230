import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserSettings } from '../models/user-settings.model';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {
  private readonly STORAGE_KEY = 'userSettings';
  private settingsSubject: BehaviorSubject<UserSettings | null>;

  constructor() {
    const storedSettings = this.loadFromStorage();
    this.settingsSubject = new BehaviorSubject<UserSettings | null>(storedSettings);
  }

  private loadFromStorage(): UserSettings | null {
    try {
      const settings = localStorage.getItem(this.STORAGE_KEY);
      if (!settings) return null;
      
      const parsedSettings = JSON.parse(settings);
      // Si no hay nombre o está vacío, consideramos que no hay configuración
      if (!parsedSettings.name || parsedSettings.name.trim() === '') {
        return null;
      }
      
      return new UserSettings(parsedSettings);
    } catch {
      return null;
    }
  }

  private saveToStorage(settings: UserSettings): void {
    if (!settings.name || settings.name.trim() === '') {
      console.error('Intento de guardar settings sin nombre');
      return;
    }
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(settings));
  }

  getSettings(): Observable<UserSettings | null> {
    return this.settingsSubject.asObservable();
  }

  getCurrentSettings(): UserSettings | null {
    const settings = this.settingsSubject.getValue();
    // Verificación adicional para asegurar que tenemos un nombre válido
    if (!settings?.name || settings.name.trim() === '') {
      return null;
    }
    return settings;
  }

  updateSettings(settings: Partial<UserSettings>): void {
    const currentSettings = this.settingsSubject.getValue();
    const newSettings = new UserSettings({ 
      ...(currentSettings || {}), 
      ...settings 
    });

    // No permitimos guardar settings sin nombre
    if (!newSettings.name || newSettings.name.trim() === '') {
      console.error('No se puede actualizar settings sin nombre');
      return;
    }

    this.settingsSubject.next(newSettings);
    this.saveToStorage(newSettings);
  }

  isUserConfigured(): boolean {
    const settings = this.getCurrentSettings();
    return settings !== null && !!settings.name;
  }

  clearSettings(): void {
    localStorage.removeItem(this.STORAGE_KEY);
    this.settingsSubject.next(null);
  }
}
