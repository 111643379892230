import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserSettingsService } from '../services/user-settings.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileGuard implements CanActivate {
  constructor(
    private userSettingsService: UserSettingsService,
    private router: Router
  ) {}

  canActivate(): boolean {
    const settings = this.userSettingsService.getCurrentSettings();
    const hasValidName = settings?.name && settings.name.trim() !== '';

    if (!hasValidName) {
      this.router.navigate(['/profile']);
      return false;
    }

    return true;
  }
}
